//import axios from "axios";
import Cookies from "js-cookie";

//const ENDPOINT_PATH = "/api/v1/usuario/login";
export default {

  setUserLogged(Logged) {
    Cookies.set("token", Logged[0]);
    Cookies.set("customer", Logged[1]);
    Cookies.set("rut", Logged[2]);
    Cookies.set("userType", Logged[3]);
    Cookies.set("id", Logged[4]);
    //window.location.href = "http://localhost:8080";
    window.location.href = "http://test.sipymex.cl/";


  },
  getUserToken() {
    return Cookies.get("token");
  },
  getUserName() {
    return Cookies.get("customer");
  },
  getUserRut() {
    return Cookies.get("rut");
  },
  getUserType() {
    return Cookies.get("userType");
  },
  getUserId() {
    return Cookies.get("id");
  },
  exitSession() {
    Cookies.remove("token");
    Cookies.remove("customer");
    Cookies.remove("rut");
    Cookies.remove("userType");
    Cookies.remove("id");
    //window.location.href = "http://localhost:8080";
   window.location.href = "http://test.sipymex.cl/";
  },


};
