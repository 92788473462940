const config = {
    baseUrl: "https://api.sipymex.cl/api/",
    rutAdmin: "77180056-4",
    idBoletaElectronica: 1,
    idFacturacionElectronica: 2,
    idFirmaElectronica: 3,
    idHosting: 4,

};

export default config;
