<template>
  <v-app>
    <Menu></Menu>
    <v-main>
      <v-row v-if="showMenu" >
        <v-col cols="12" md="2">
          <SideBar></SideBar>
        </v-col>
        <v-col cols="12" md="10" class="pr-8">
          <router-view></router-view>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" lg="12">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-main>
    <Footer class="" style="z-index:3"></Footer>
  </v-app>
</template>
<script>
import axios from "axios";
import Menu from "./Menu.vue";
import Footer from "./Footer.vue";
import auth from "@/logic/auth";
export default {
  data: () => ({
    showMenu: false,
    rut: 0,
    id_customer: 0,
  }),
  mounted() {
    this.showMenuMethod();
    this.routes();
  },

  component: { Menu, Footer },
  methods: {
    showMenuMethod() {
      this.showMenu = auth.getUserType() != undefined;
      console.log("variable showMenu -> " + this.showMenu);
      console.log("auth.getUserType() -> " + auth.getUserType());
    },
    routes() {
      console.log("entro al mounted routes");
      if (auth.getUserType() == "admin") {
      //  this.$router.push({ name: "dashboard" });
      } else if (auth.getUserType() == "user") {
        let request = {
          rut: auth.getUserRut(),
        };
        console.log("imprime el rut por el rut en el layout " + this.rut);
        axios
          .post("/customer-by-rut", request)
          .then((response) => {
            this.id_customer = response.data.id;
            console.log("imprime response en layout -> " + response.data);
          })
          .finally(() => {
            console.log("imprime el id finally " + this.id_customer);
            this.$router.push({
              name: "my-debts",
              params: { id: this.id_customer },
            });
          });
      } else {
     //   this.$router.push({ name: "home" });
      }
    },
  },
  computed: {
    show() {
      console.log("entra a mostrar menu");
      console.log("variable show -> " + auth.getUserType());
      return auth.getUserType() != undefined;
    },
  },
};
</script>