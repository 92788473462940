<template>
  <div>
    <v-app-bar height="60" style="z-index: 100;" color="white" app fixed>
      
      <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-sm-none" >
      </v-app-bar-nav-icon>
      <router-link to="/home"><v-img src="@/assets/logo.png" class="mx-2" max-width="140" contain></v-img>
      </router-link>
      <v-spacer></v-spacer>
    
     
      <v-bottom-navigation class="hidden-sm-and-down  btn-navigations" horizontal><v-btn to="/home">
          <span>Inicio</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn to="/nosotros">
          <span tag="buttom"> Nosotros</span>
          <v-icon>mdi-account-group</v-icon>
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <span>Servicios</span>
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in submenus"
              :key="index"
              :to="item.link"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn to="/equipos">
          <span tag="buttom">Equipos</span>
          <v-icon>mdi-desktop-classic</v-icon>
        </v-btn>

        <v-btn to="/preguntas-frecuentes">
          <span tag="buttom">FAQS</span>
          <v-icon>mdi-frequently-asked-questions</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn     
        text
      color="primary"  
        to="/get-debts-by-rut"
      >
        PAGA TU CUENTA
        <v-icon>mdi-credit-card-outline</v-icon>
      </v-btn>
     

      <v-btn  
      text
      color="primary"   
        tag="buttom"
        to="/contacto"
      >
      CONTÁCTANOS  <v-icon>mdi-phone-in-talk</v-icon>
      </v-btn>

      <v-btn
      text
      color="primary"
        to="/login"        
      >
        ENTRAR <v-icon>mdi-account-lock-open</v-icon>
      </v-btn>
        
     
        <v-toolbar-items class="hidden-sm-and-down">
      </v-toolbar-items>
      </v-bottom-navigation>      
    </v-app-bar>

    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <div v-for="(item, i) in items" :key="i">
      <v-list-item :to="item.to" v-if="!item.subLinks">
        <v-list-item-icon>
          <v-icon>{{item.icon}}</v-icon>
        </v-list-item-icon> 
        <v-list-item-title>{{item.title}}</v-list-item-title>
      </v-list-item>

      <v-list-group v-else
        :key="item.title"
        :prepend-icon="item.icon"
            no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.subLinks"
          :key="child.title"
           :to="item.to" 
        >
          <v-list-item-content>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </div>
    </v-list>
    <v-divider></v-divider>
    <v-list>
        <div v-for="(item, i) in items_menu" :key="i">
      <v-list-item :to="item.to" v-if="!item.subLinks">
        <v-list-item-icon>
          <v-icon>{{item.icon}}</v-icon>
        </v-list-item-icon> 
        <v-list-item-title>{{item.title}}</v-list-item-title>
      </v-list-item>

      <v-list-group v-else
        :key="item.title"
        :prepend-icon="item.icon"
            no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.subLinks"
          :key="child.title"
           :to="item.to" 
        >
          <v-list-item-content>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </div>
    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Menu",
  items: [],
  data() {
    return {
      drawer: false,
      tab: null,
      submenus: [
        { title: "Boleta Electrónica", link: "/boleta-electronica", icon: "" },
        { title: "Facturación Electrónica", link: "/facturacion-electronica", icon: "" },
        { title: "Firma Electrónica", link: "/firma-electronica", icon: "" },
      //  { title: "Hosting", link: "/hosting", icon: "" },
      ],
      items: [
        {
          title: "Inicio",
          to: "/home",
          icon: "mdi-home"
        },
        {
          title: "Nosotros",
          to: "/nosotros",
          icon: "mdi-account-group"
        },
        {
          title: "Servicios",
          to: "",
          icon: "mdi-view-list",
          subLinks: [
            {
              to: '/boleta-electronica',
              title: 'Boleta Electrónica',
              icon: ""
            },
            {
              to: '/facturacion-electronica',
              title: 'Facturación Electrónica',
              icon: ""
            },
            {
              to: '/firma-electronica',
              title: 'Firma Electronica',
              icon: ""
            },
            {
              to: '/hosting',
              title: 'Hosting',
              icon: ""
            },
          ],
        },
        {
          title: "Equipos",
          to: "/equipos",
          icon: "mdi-desktop-classic"
        },
        {
          title: "FAQS",
          to: "/preguntas-frecuentes",
          icon: "mdi-frequently-asked-questions"
        },
      ],
      items_menu: [
        {
          title: "PAGA TU CUENTA",
          to: "/get-debts-by-rut",
          icon: "mdi-credit-card-outline"
        },
        {
          title: "CONTÁCTANOS",
          to: "/contacto",
          icon: "mdi-phone-in-talk"
        },

        {
          title: "ENTRAR",
          to: "/login",
          icon: "mdi-account-lock-open"
        },
      ]
    };
  },
  methods: {
    menuItems() {
      return this.menu;
    }
  }
};

</script>

<!--
<template>
  <div class="home">
    <section class="hero">
      <div class="hero-text container">
        <h4>Menu</h4>
        <hr>
      </div>
    </section>
  </div>
</template>
<script>
  export default{
    name:"Menu",
    components
  };
</script>

-->



